<template>
    <div class="flex-grow-1 d-flex flex-column" style="height: 250px;">
        <div class="mt-2" style="padding: 10px;border-bottom: 1px solid #eee">
            返回信息：<span class="mr-3" v-for="(value,key) in events.status" :key="key"><b>{{key}}:</b>{{value}}</span>
        </div>
        <el-progress status="success" :text-inside="size!=='small'" :stroke-width="size!=='small'?26:12"
                     :percentage="pct" class="mt-3" v-if="progress"></el-progress>
        <div class="flex-grow-1 " style="overflow-y: auto;overflow-x: hidden;" ref="wait_scroll_window">
            <ul style="padding: 10px;">
                <li v-for="(x,index) in events.messages" :key="index" :class="{'font-small':size==='small'}">
                    <span class="text-info">{{x}}</span>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
    export default {
        name: "PusherInformationBox",
        data() {
            return {}
        },
        props: {
            events: {
                type: Object, default() {
                    return {message: [], status: {}}
                }
            },
            progress: {
                type: Boolean, default() {
                    return false;
                }
            },
            size: {
                type: String, default() {
                    return '';
                }
            }
        },
        watch: {
            events: {
                handler() {
                    this.$nextTick(() => {
                        const div = this.$refs['wait_scroll_window'];
                        if (div) div.scrollTop = div.scrollHeight;
                    });
                },
                'deep':true
            }
        },
        computed: {
            pct() {
                let pct = 0;
                if (this.events && this.events.status && this.events.status['总共'] && this.events.status['完成']) {
                    let total = parseInt(this.events.status['总共']);
                    if (total === 0) total = 1;
                    const index = parseInt(this.events.status['完成']);
                    pct = Math.floor(100 * index / total);
                }
                return pct;
            }
        },
    }
</script>

<style scoped>
    .font-small {
        font-size: 85%;
    }
</style>
