<template>
    <section class="">
        <front-table :front_page="page"
                     :front_records="records"
                     :front_search="search"
                     :front_header="header"
                     :model="model"
                     @reload="handleReload"
                     @edit="handleEdit"
                     btn_copy
                     @copy="handleCopy"

        >
            <div class="pull-right">
                <el-button type="text" icon="el-icon-coffee-cup" @click="batch=true">同步用户信息</el-button>
            </div>
        </front-table>
        <!--编辑界面-->
        <edit-dialog :edit_mode="edit_mode" :model="model" :single="single" :fields="fields" dual
                     @success="handleSuccess" @cancel="handleCancel"></edit-dialog>

        <div class="clearfix"></div>
        <!--批量界面-->
        <el-dialog :visible.sync="batch" title="同步用户信息" @close="disconnectPusher" @open="connectPusher">
            <div class="text-info">点击同步，开始同步腾讯网上信息</div>
            <pusher-information-box :events="events" progress></pusher-information-box>
            <div slot="footer" class="">
                <el-button :loading="process.loading" size="medium" @click="syncTMUsers">同步用户</el-button>
                <el-button type="primary" @click="batch=false" size="medium">返回</el-button>
            </div>
        </el-dialog>

    </section>
</template>

<script>
    import EditDialog from "../components/edit-dialog";
    import FrontTable from "../components/front-table";
    import FrontMixins from "../common/mixins/front-mixins";
    import PushMixin from "../common/mixins/echo-mixin"
    import PusherInformationBox from "../components/PusherInformationBox";

    export default {
        mixins: [FrontMixins,PushMixin],
        name: 'admin-tm-user',
        components: {PusherInformationBox, FrontTable, EditDialog},
        data() {
            return {
                batch: false,
                payload: {id:'1',max:2},
                rooms: {},
                schedules:[],
            }
        },
        created() {
            this.model = 'Admin.TMUser';
        },
        methods: {
            syncTMUsers() {
                const vm = this;
                this.$api(this.model+'.publish',this.addChannel(this.payload),1).then((res)=>{
                    vm.$notice.success(res.data.data);
                    vm.reload();
                    //vm.batch = false;
                });
            },
        }
    }

</script>

<style lang="scss">

</style>
